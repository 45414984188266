let backUrl = "/"

const { NODE_ENV, VUE_APP_NGINX } = process.env

if (NODE_ENV === 'development') {
  backUrl = 'https://dev.beast.mts.ru/'
  // backUrl = 'https://demo.beast.mts.ru/'
  // backUrl = 'https://external.dev.beast.mts.ru/'
  // backUrl = 'https://external.demo.beast.mts.ru/'
}

/** For debugging on local as like "production" */
if (VUE_APP_NGINX) {
  backUrl = 'https://dev.beast.mts.ru/'
}

export { backUrl }