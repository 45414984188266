import { checkLeadZero } from "../Common";
import { namedDay, namedMounth, pluralNamedMounth } from "./constants";
import cloneDeep from "lodash-es/cloneDeep";

/**
 * @helper
 * Конвертирует в обьект Даты
 *
 * @param {date} date - дата которую надо конвертировать в обьект Даты
 * @return {Date} Обьект Даты
 */
 export const _toDate = date => date instanceof Date ? date : new Date(date)

/**
 * Вычисляет разницу времени в секундах
 *
 * @param {date} date - Дата от которой надо посчитать
 * @param {date=} now - Дата к которой надо посчитать
 * @return {int} Разница времени в секундах
 */
export const diffPerSeconds = function (date, now = new Date()) {
  var differenceTimeExport = _toDate(date).getTime() - _toDate(now).getTime()
  return Math.floor((differenceTimeExport) / (1000))
 }
 /**
  * Вычисляет разницу времени в минутах
  *
  * @param {date} date - Дата от которой надо посчитать
  * @param {date=} now - Дата к которой надо посчитать
  * @return {int} Разница времени в минутах
  */
 export const diffPerMinutes = (date, now) => (
   Math.floor(diffPerSeconds(date, now) / 60)
 )

 /**
  * Вычисляет разницу времени в часах
  *
  * @param {date} date - Дата от которой надо посчитать
  * @param {date=} now - Дата к которой надо посчитать
  * @return {int} Разница времени в часах
  */
 export const diffPerHours = (date, now) => (
   Math.floor(diffPerMinutes(date, now) / 60)
 )

 /**
  * Вычисляет разницу времени в днях
  *
  * @param {date} date - Дата от которой надо посчитать
  * @param {date=} now - Дата к которой надо посчитать
  * @return {int} Разница времени в днях
  */
 export const diffPerDays = (date, now) => (
   Math.floor(diffPerHours(date, now) / 24)
 )

 /**
  * Вычисляет разницу времени в будних(!) днях без суббот и воскресений
  *
  * @param {date} startDate - Текущее время
  * @param {date} endDate - Время в будущем
  * @return {int} Разница времени в днях
  */
 export const diffPerDaysWithoutWeekend = (endDate, startDate) => {
   let count = 0;

   let startDateLocal = cloneDeep(startDate)

   while (startDateLocal <= endDate) {
    const dayOfWeek = startDateLocal.getDay();

    if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
    startDateLocal.setDate(startDateLocal.getDate() + 1);
   }

   return count;
 }

 /**
 * Возвращает дату в будущем которая отличается от текущей на указанное количество дней без учета выходных
 *
 * @param {date} startDate - Заданная дата
 * @param {int} days - Разница времени в днях
 * @return {date} Искомая дата
 */
 export const getDateFromCurrentWithoutWeekend = (startDate, days) => {
  let count = 0;

  let resultDate = cloneDeep(startDate)

  while (count < days) {
   const dayOfWeek = resultDate.getDay();

   if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;

   if (count !== days) {
    resultDate.setDate(resultDate.getDate() + 1);
   }
  }

  return resultDate;
 }

 /**
  * Вычисляет разницу в месяцах между датами.
  * @param {Date} d1 Дата 1
  * @param {Date} d2 Дата 2
  * @return {number} Кол-во месяцев
  */
 export const diffPerMonth = function (d1, d2) {
  d1 = _toDate(d1)
  d2 = _toDate(d2)
  let months
  months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth()
  months += d2.getMonth()
  return months
 }

 /**
  * Вычисляет разницу в годах между датами.
  * @param {Date} d1 Дата 1
  * @param {Date} d2 Дата 2
  * @return {number} Кол-во годов
  */
 export const diffPerYear = function (d1, d2) {
  d1 = _toDate(d1)
  d2 = _toDate(d2)
  return d2.getFullYear() - d1.getFullYear()
 }

/**
 * Краткий формат записи месяца в дате без года
 * @param {string} [date] -  Строка формата даты/времени
 * @return {string} строковый формат даты типа 31 дек.
 **/

export const shortMonthFormat = function(date) {
 return new Date(date).toLocaleDateString('ru', {
  day: 'numeric',
  month: 'short'
 })
}

/**
 * Краткий формат записи месяца в дате с годом
 * @param {string} [date] -  Строка формата даты/времени
 * @return {string} строковый формат даты типа 31 дек. 2001
 **/

export const shortMonthFormatWithYear = function(date) {
 return new Date(date).toLocaleDateString('ru', {
  day: 'numeric',
  month: 'short',
  year: 'numeric'
 })
}

/**
 * Краткий формат записи месяца в дате с годом из двух чисел
 * @param {string} [date] -  Строка формата даты/времени
 * @return {string} строковый формат даты типа 31 дек. 01
 **/

export const shortMonthFormatWithTwoDigitsYear = function(date) {
 return new Date(date).toLocaleDateString('ru', {
  day: 'numeric',
  month: 'short',
  year: '2-digit'
 })
}

/**
 * Возвращает разбитую на составляющие дату текущую
 * или заданную в GMT(т.е. "с" учетом часового пояса)
 *
 * @param {Date} [date=now] Дата/Время
 * @param {object} Разбитый обьект даты
 */
 export const getSplitDate = function (date = new Date()) {
  let output = {}
  let _date = _toDate(date)

  if (_date == 'Invalid Date') {
    console.warn(`The passed value "${ date }", is not a date!`)
    return null
  }
  /* Year */
  output['YYYY']  = _date.getFullYear() // eg. 2020
  output['YY']    = _date.getFullYear().toString().substring(2, 4) // eg. 20
  output['Y']     = output['YYYY']

  /* Month */
  output['MM']    = checkLeadZero(_date.getMonth() + 1) // eg. 05
  output['M']     = _date.getMonth() + 1 // +1 потому что месяцы в JS начинаются с "0", eg. 5

  output["FFFF"]  = pluralNamedMounth[_date.getMonth()].full // eg. Апреля
  output["FFF"]   = namedMounth[_date.getMonth()].full //eg. Апрель
  output["FF"]    = namedMounth[_date.getMonth()].short //eg. Апр

  output["ffff"]  = (pluralNamedMounth[_date.getMonth()] || "").full.toLowerCase() // eg. апреля
  output["fff"]   = (namedMounth[_date.getMonth()] || "").full.toLowerCase() // eg. апрель
  output["ff"]    = (namedMounth[_date.getMonth()] || "").short.toLowerCase() // eg. апр
  output["f"]    = (pluralNamedMounth[_date.getMonth()] || "").short.toLowerCase() // eg. апр, мая

  /* Human day */
  output["dddd"]  = namedDay[_date.getDay()].plural, // eg. пятницУ
  output["ddd"]   = namedDay[_date.getDay()].full, // eg. пятница
  output["dd"]    = namedDay[_date.getDay()].middle, // eg. птн
  output["d"]     = namedDay[_date.getDay()].small, // eg. пт

  /* Days */
  output['DD']    = checkLeadZero(_date.getDate()) // eg. 08, 10, 20
  output['D']     = _date.getDate() // eg. 8, 10, 20

  /* Hour */
  output['HH']    = checkLeadZero(_date.getHours()) // eg. 05
  output['H']     = _date.getHours() //eg. 5

  /* Minutes */
  output['mm']    = checkLeadZero(_date.getMinutes()) // eg. 05
  output['m']     = _date.getMinutes() // eg. 5

  /* Seconds */
  output['ss']    = checkLeadZero(_date.getSeconds()) // eg. 05
  output['s']     = _date.getSeconds() //eg. 5

  return output
}

/**
 * Возвращает разбитую на составляющие дату текущую
 * или заданную в UTC(т.е. "без" учета часового пояса)
 *
 * @param {Date} [date=now] Дата/Время
 * @param {object} Разбитый обьект даты
 */
export const getSplitUTCDate = function (date = new Date()) {
 let output = {}
 let _date = _toDate(date)

 if (_date == 'Invalid Date') {
  console.warn(`The passed value "${ date }", is not a date!`)
  return null
 }

 output['YYYY'] = _date.getUTCFullYear()
 output['YY']   = _date.getUTCFullYear().toString().substring(2, 4)
 output['Y'] = output['YYYY']

 output['MM']   = checkLeadZero(_date.getUTCMonth() + 1)
 output['M']    = _date.getUTCMonth() + 1 // +1 потому что месяцы в JS начинаются с "0"

 output["FFFF"]  = pluralNamedMounth[_date.getUTCMonth()].full // eg. Апреля
 output["FFF"]   = namedMounth[_date.getUTCMonth()].full //eg. Апрель
 output["FF"]    = namedMounth[_date.getUTCMonth()].short //eg. Апр

 output["ffff"]  = (pluralNamedMounth[_date.getUTCMonth()] || "").full.toLowerCase() // eg. апреля
 output["fff"]   = (namedMounth[_date.getUTCMonth()] || "").full.toLowerCase() // eg. апрель
 output["ff"]    = (namedMounth[_date.getUTCMonth()] || "").short.toLowerCase() // eg. апр

 /** Human day */
 output["dddd"]  = namedDay[_date.getDay()].plural, // eg. пятницУ
 output["ddd"]  = namedDay[_date.getDay()].full, // eg. пятница
 output["dd"]   = namedDay[_date.getDay()].middle, // eg. птн
 output["d"]    = namedDay[_date.getDay()].small, // eg. пт

 output['DD']   = checkLeadZero(_date.getUTCDate())
 output['D']    = _date.getUTCDate()

 output['HH']   = checkLeadZero(_date.getUTCHours())
 output['H']    = _date.getUTCHours()

 output['mm']   = checkLeadZero(_date.getUTCMinutes())
 output['m']    = _date.getUTCMinutes()

 output['ss']   = checkLeadZero(_date.getUTCSeconds())
 output['s']    = _date.getUTCSeconds()

 return output
}

/*
 * Собирает из массива строку
 * @param {string} [format] - Строка формата даты/времени
 * @param {object} [date] - Разбитый обьект даты на сущности(день, месяц, год, ...)
 * @return {string} строковый представление формата даты/времени
 */
 function _splitDateToString (format, splitDate) {
  if (!splitDate) return null

  let _ret = format

  for (let key in splitDate) {
    _ret = _ret.replace(key, splitDate[key])
  }

  return _ret
}

/**
 * Возвращает текущий или заданный штамп времени (GMT) в строковом формате.
 *
 * @param {string} [format=DD.MM.YYYY HH:mm:ss] - Формат даты/времени
 * @param {date} [date] - Дата/Время
 * @return {string} строковый формат даты/времени
 */
export const getStringOfTimespamp = function (format = 'DD.MM.YYYY HH:mm:ss', date = new Date()) {
  const splitDate = getSplitDate(_toDate(date))
  return _splitDateToString(format, splitDate)
}

/**
 * Синоним для getStringOfTimespamp
 */
export const format = getStringOfTimespamp

/**
 * Синоним для getStringOfTimespamp
 */
export const formatGMT = getStringOfTimespamp

/**
 * Возвращает текущий или заданный штамп времени (UTC) в строковом формате.
 *
 * @param {string} [format=DD.MM.YYYY HH:mm:ss] - Формат даты/времени
 * @param {date} [date] - Дата/Время
 * @return {string} строковый формат даты/времени
 */
export const getStringOfUTCTimespamp = function (format = 'DD.MM.YYYY HH:mm:ss', date = new Date()) {
  const splitDate = getSplitUTCDate(_toDate(date))
  return _splitDateToString(format, splitDate)
}

/**
 * Синоним для getStringOfUTCTimespamp
 */
export const formatUTC = getStringOfUTCTimespamp

/**
 * Возвращает текстовое представление прошеднего времени типа HH:MM:SS
 * с переданного момента до текущего момента времени
 *
 * @param {date} last - Время в прошлом
 * @return {string} Прошедшее время в виде HH:MM:SS
 */
export const elapsedTimeFormat = function (last) {
 var sec = diffPerSeconds(last)
 var hours = Math.floor(sec / 3600)
 var minutes = Math.floor((sec - (hours * 3600)) / 60)
 var seconds = sec - (hours * 3600) - (minutes * 60)

 return checkLeadZero(hours) + ':' + checkLeadZero(minutes) + ':' + checkLeadZero(seconds)
}

/**
 * Парсит переданную дату по маске формата приложения,
 * определенного в конфиге.
 * @param {string} stringDate Дата в строке
 * @return {(number|null)} Распарсенная дата в unixtimestamp или null
 */
export const parseDate = function (stringDate, dateFormat) {

 if (!dateFormat) {
  console.warn("Не передан строковый формат даты")
  return null
 }

 let _result = {}
 for (let i = 0; i < dateFormat.length; i++) {
  const char = dateFormat[i]
  if (!_result[char]) {
   _result[char] = stringDate[i]
  } else {
   _result[char] = _result[char] + stringDate[i]
  }
 }

 const _date = dateFromSplit(_result)

 return _date != 'Invalid Date' ? _date.getTime() : null
}

/**
 * Генерирует дату на основе частей.
 * @see getSplitDate
 * @param {object} splited Обьект разбитой на части даты
 * @returns {Date}
 */
export const dateFromSplit = splited => {
 const { Y, M = 1, D = 1, H = 0, m = 0, s = 0 } = splited ?? {}
 return new Date(
   Number(Y),
   Number(M) - 1,
   Number(D),
   Number(H),
   Number(m),
   Number(s)
 )
}

/**
 * Конвертирует в ISO string без учета TimeZone
 * @param {Date} date
 * @return {String} GMT ISO string
 */
export const parseISODate = stringDate => parseDate(stringDate, "YYYY-MM-DDTHH:mm:ssZ")

/**
 * Конвертирует в ISO string с учетом TimeZone
 * Например, пришло 2020-01-30T00:00:00Z, ушло 2020-01-29T21:00:00.000Z
 * @param {Date} date
 * @return {String} GMT ISO string
 */
export const dateToGMTString = function (date = new Date()) {
 let _date = _toDate(date)
 const offsetMs = _date.getTimezoneOffset() * 60000
 let time = _date.getTime() + offsetMs
 return new Date(time).toISOString()
}

