/**
 * Плагин следит за изменением размера окна браузера.
 * Идея взята с vuetify v1.5.x
 * 
 * @author Lobkov Alexandr <aslobko1@mts.ru>
 */

import debouce from "lodash-es/debounce"
import { getVarValue } from "plugs/colors"
import { isNumber } from "plugs/utils"
import { Hiders } from "./Hiders"
import { helpers } from "./helpers"

const _build = Vue => {

  const mobileBreakpoint = parseInt(getVarValue("mobileBreakpoint"))
  const scrollBarWidth = parseInt(getVarValue("scrollBarWidth"))
  const thresholds = getVarValue("gridBreakpoints", true)

  /**
   * Для корректной работы системы отпределения точек остановки сетки
   * необходимы все параметры выше перечисленные, если хоть одного нет
   * "отключаем" плагин
   */
  if (!mobileBreakpoint || !scrollBarWidth || !thresholds) return undefined

  const dataPoints = Object.keys(thresholds).reduce((acc, t) => {
    acc[`${ t }`] = undefined
    acc[`${ t }Only`] = undefined
    acc[`${ t }AndDown`] = undefined
    acc[`${ t }AndUp`] = undefined
    return acc
  }, {})

  return new Vue({
    name: "breakpoint-observer",
    render: () => null,
    data: () => ({
      ...dataPoints,
      name: "xs",
      height: 0,
      width: 0,
      onMobile: undefined,
      onDesktop: undefined,
      mobileBreakpoint,
      thresholds,
      scrollBarWidth,
      // xs: false,
      // sm: false,
      // md: false,
      // lg: false,
      // xl: false,
      // xxl: false,
      // xsOnly: false,
      // smOnly: false,
      // smAndDown: false,
      // smAndUp: false,
      // mdOnly: false,
      // mdAndDown: false,
      // mdAndUp: false,
      // lgOnly: false,
      // lgAndDown: false,
      // lgAndUp: false,
      // xlOnly: false,
      // xlAndDown: false,
      // xlAndUp: false,
      // xxlOnly: false,
    }),
    created () {
      this.debouceOnResize()

      window.addEventListener(
        'resize',
        this.debouceOnResize,
        { passive: true }
      )
    },
    computed: {
      debouceOnResize: ({ onResize }) => debouce(onResize, 100),
    },
    methods: {
      onResize () {
        const height = this.getClientHeight()
        const width = this.getClientWidth()
    
        // const xs = width < this.thresholds.sm
        // const sm = width < this.thresholds.md && !xs
        // const md = width < this.thresholds.lg && !(sm || xs)
        // const lg = width < this.thresholds.xl && !(md || sm || xs)
        // const xl = width < this.thresholds.xxl && !(lg || md || sm || xs)
        // const xxl = width >= this.thresholds.xxl

        /**
         * Вычисляем все точки которые меньше или равны текущей ширине экрана.
         */
        const treshCalc = Object.values(this.thresholds).reduce((acc, _v) => {
          if (width >= _v) acc.push(_v)
          return acc
        }, [])

        /**
         * Берем максимальную точку.
         */
        const currentSize = Math.max(...treshCalc)

        /**
         * Находим имя этой точки по ширине.
         */
        const pointName = Object.keys(this.thresholds).find(key => this.thresholds[key] === currentSize)

        if (!pointName) {
          console.warn(`Ошибка вычисления "точек остановки". Не найдена точка остановки для ширины ${ currentSize }!`)
          return
        }
        
        this.name = pointName
        this.height = height
        this.width = width

        this.setTresholdsOnly()
        this.setTresholdsLessMore()

        // this.xs = xs
        // this.sm = sm
        // this.md = md
        // this.lg = lg
        // this.xl = xl
        // this.xxl = xxl
    
        // this.xsOnly = xs

        // this.smOnly = sm
        // this.smAndDown = (xs || sm) && !(md || lg || xl || xxl)
        // this.smAndUp = !xs && (sm || md || lg || xl || xxl)

        // this.mdOnly = md
        // this.mdAndDown = (xs || sm || md) && !(lg || xl || xxl)
        // this.mdAndUp = !(xs || sm) && (md || lg || xl || xxl)

        // this.lgOnly = lg
        // this.lgAndDown = (xs || sm || md || lg) && !(xl || xxl)
        // this.lgAndUp = !(xs || sm || md) && (lg || xl || xxl)

        // this.xlOnly = xl
        // this.xlAndDown = (xs || sm || md || lg || xl) && !xxl
        // this.xlAndUp = !(xs || sm || md || lg) && (xl || xxl)

        // this.xxlOnly = xxl
    
        // switch (true) {
        //   case (xs):
        //     this.name = 'xs'
        //     break
        //   case (sm):
        //     this.name = 'sm'
        //     break
        //   case (md):
        //     this.name = 'md'
        //     break
        //   case (lg):
        //     this.name = 'lg'
        //     break
        //   case (xl):
        //     this.name = 'xl'
        //     break
        //   default:
        //     this.name = 'xxl'
        //     break
        // }
    
        if (isNumber(this.mobileBreakpoint)) {
          this.onMobile = width < parseInt(this.mobileBreakpoint, 10)
          this.onDesktop = !this.onMobile
    
          return
        }
    
        // const breakpoints = {
        //   xs: 0,
        //   sm: 1,
        //   md: 2,
        //   lg: 3,
        //   xl: 4,
        // }
    
        // const current = breakpoints[this.name]
        // const max = breakpoints[this.mobileBreakpoint]
    
        // this.mobile = current <= max
      },
      /**
       * Обходит все доступные "точки" и присвивает true текущей точке.
       */
      setTresholdsOnly () {
        const {
          name: n,
          thresholds: ts
        } = this
        Object.keys(ts).forEach(t => this[t] = t === n)
      },
      /**
       * Вычисляем отношение точек между точками.
       */
      setTresholdsLessMore () {
        const {
          width: w,
          name: n,
          thresholds: ts
        } = this
        
        Object.keys(ts).forEach(t => {
          const pointPx = ts[t]
          this[`${ t }Only`] = t === n
          this[`${ t }AndUp`] = t === n || pointPx <= w
          this[`${ t }AndDown`] = t === n || pointPx >= w
        })
      },
      getClientWidth () {
        if (typeof document === 'undefined') return 0 // SSR
        /** Берем ширину экрана без учета scrollbar'а */
        return document.documentElement.clientWidth

        /** Берем ширину экрана с учетом scrollbar'а */
        // return Math.max(
        //   document.documentElement.clientWidth,
        //   window.innerWidth || 0
        // )
      },
      getClientHeight () {
        if (typeof document === 'undefined') return 0 // SSR
        /** Берем высоту экрана без учета scrollbar'а */
        return document.documentElement.clientHeight

        /** Берем высоту экрана с учетом scrollbar'а */
        // return Math.max(
        //   document.documentElement.clientHeight,
        //   window.innerHeight || 0
        // )
      }
    }
  })
}

export const breakPoints = {
  install: Vue => {
    const breaks = _build(Vue)

    // Отклоняем установку плагина, потому что компонент не создался.
    if (!breaks) return undefined

    Object.defineProperty(Vue.prototype, "$breakpoints", {
      enumerable: false,
      configurable: false,
      value: breaks.$data
    })

    /**
     * Устанавливаем "скрывателей"
     */
    Vue.use(Hiders, breaks.$data)

    /**
     * Устанавливаем "помошников"
     */
    Vue.use(helpers, breaks.$data)
  }
}